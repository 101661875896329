
#scaleLP:hover {
    transform: scale(0.9);
  }
#scaleMedicoLP:hover {
    transform: scale(0.95);
  }

#div2 {
    background-image: url('../../Assets//grafismoamarelo.png'),url('../../Assets//grafismoamarelo.png');
    background-position: top -200px left -200px, bottom -90px right -200px;
  background-repeat: no-repeat, no-repeat;
  background-size: 55%;
    
}

@media (max-width: 767px) {
    #div2 {
        background-position: top left, bottom 110px right -20px; /* Alterando o tamanho da imagem para 100% quando a largura da tela for inferior a 768 pixels */
    }
}

/* styles.css */
.swiper-scrollbar {
    background-color: white;
    /* Estilo da barra de rolagem */
     /* Cor de fundo da barra de rolagem */
}

.swiper-scrollbar-drag {
    background-color: white;
    /* Estilo do controle deslizante da barra de rolagem */
    /* Cor do controle deslizante da barra de rolagem */
}

.swiper-button-prev {
    color: #663780;
    

    

}

.swiper-button-next {
    /* Estilo das setas de navegação */
    color: #663780;
   

    
}
